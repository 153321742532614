// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "cY_wj";
export var approachInfoRoundBlock__card = "cY_wh";
export var approachInfoRoundBlock__contentFive = "cY_wt";
export var approachInfoRoundBlock__contentFour = "cY_ws";
export var approachInfoRoundBlock__contentOne = "cY_wp";
export var approachInfoRoundBlock__contentSix = "cY_wv";
export var approachInfoRoundBlock__contentThree = "cY_wr";
export var approachInfoRoundBlock__contentTwo = "cY_wq";
export var approachInfoRoundBlock__description = "cY_wn";
export var approachInfoRoundBlock__icon = "cY_wl";
export var approachInfoRoundBlock__imageDesktop = "cY_wk";
export var approachInfoRoundBlock__title = "cY_wm";