// extracted by mini-css-extract-plugin
export var agileApproachSection = "k_R";
export var agileApproachSection__testimonial = "k_S";
export var bestPracticesApproachSection = "k_T";
export var caseApproachSection = "k_1";
export var processApproachSection = "k_Y";
export var projectSuccessApproachSection = "k_V";
export var recentArticlesApproachSection = "k_0";
export var successStoriesApproachSection = "k_2";
export var techStackApproachSection = "k_Z";
export var testimonialApproachSection = "k_W";
export var toolslApproachSection = "k_X";